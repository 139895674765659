import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const Vimeo = dynamic(() => import('@u-wave/react-vimeo'));
import Player from '@vimeo/player';

import i18n from '../../i18n';
import styles from './VideoModule.module.scss';
import Icon from '../Icon';
import classNames from 'classnames';

const Video = ({ iframeAttributes, onPlay = () => null, pause }) => {
    const iframeRef = useRef();
    const [player, setPlayer] = useState();
    const [url, setUrl] = useState(iframeAttributes.src);

    useEffect(() => {
        const player = new Player(iframeRef.current);
        player.on('loaded', () => {
            onPlay();
        });
        player.on('play', () => {
            onPlay();
        });
        player.on('playing', () => {
            onPlay();
        });
        setPlayer(player);

        if (
            url.indexOf('vimeo') > -1 &&
            typeof window !== 'undefined' &&
            window.innerWidth < 850
        ) {
            setUrl(url + '&quality=720p');
        }
    }, []);

    useEffect(() => {
        if (!player) {
            return;
        }
        player.getPaused().then((paused) => {
            if (!paused) {
                return player.pause();
            }
            if (paused) {
                return player.play();
            }
            return null;
        });
    }, [pause]);

    return (
        <div
            style={{
                padding: '56.25% 0 0 0',
                position: 'relative',
            }}>
            <iframe
                ref={iframeRef}
                data-matomo-ignore
                src={url}
                frameBorder={iframeAttributes.frameborder}
                title={iframeAttributes.title}
                allow={iframeAttributes.allow}
                allowFullScreen=""
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            />
        </div>
    );
};

const VideoModule = ({
    image,
    videoUrl,
    title,
    preamble,
    iframeAttributes,
}) => {
    const [play, setPlay] = useState(true);
    const [videoStarted, setVideoStarted] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleCloseModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);

    const handlePlayPause = () => {
        setPlay(!play);
    };

    const handleOpenModal = () => {
        document.body.style.overflow = 'hidden';
        setPlay(false);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        document.body.style.overflow = 'visible';
        setPlay(true);
        setShowModal(false);
    };

    if (!videoUrl) {
        return null;
    }

    return (
        <div className={styles['VideoModule']}>
            <figure className={styles['VideoModule__Container']}>
                <div className={styles['VideoModule__Video']}>
                    <Video
                        iframeAttributes={iframeAttributes}
                        onPlay={() => setVideoStarted(true)}
                        pause={!play}
                    />
                </div>
                {image?.renditions?.xlarge && (
                    <img
                        className={classNames(styles['VideoModule__Image'], {
                            [styles['VideoModule__Image--VideoLoaded']]:
                                videoStarted,
                        })}
                        sizes="100vw"
                        srcSet={`${image.renditions.large.src} 1646w,
                             ${image.renditions.medium.src} 1200w,
                            ${image.renditions.small.src} 600w`}
                        src={image.renditions.small.src}
                        alt=""
                        style={{
                            objectPosition: image.focal.x + ' ' + image.focal.y,
                        }}
                    />
                )}
            </figure>

            <div className={styles['VideoModule__TextWrapper']}>
                <div className={styles['VideoModule__ButtonsWrapper']}>
                    <VideoButton
                        onClick={handleOpenModal}
                        play={false}
                        text={i18n.t('VideoModule.watchMovie')}
                    />
                    <VideoButton onClick={handlePlayPause} play={play} />
                </div>

                <section className={styles['VideoModule__InnerWrapper']}>
                    <div className={styles['VideoModule__Text']}>
                        <h1 className={styles['VideoModule__Title']}>
                            {title}
                        </h1>

                        <p className={styles['VideoModule__Preamble']}>
                            <span className="sr-only">
                                {i18n.t('generic.preamble')} :
                            </span>
                            {preamble}
                        </p>
                    </div>
                </section>
            </div>
            {showModal && (
                <VideoModal
                    handleCloseModal={handleCloseModal}
                    videoUrl={videoUrl}
                />
            )}
        </div>
    );
};

VideoModule.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    image: PropTypes.object,
};

VideoModule.defaultProps = {
    videoUrl: '',
    image: {},
};

const VideoModal = ({ handleCloseModal, videoUrl }) => (
    <div
        className={classNames(styles['VideoModule__Modal'], {
            [styles['VideoModule__Modal--Show']]: true,
        })}>
        <div className={styles['VideoModule__ModalWrapper']}>
            <div className={styles['VideoModule__ModalContent']}>
                <button
                    aria-label={i18n.t('Modal.close')}
                    className={styles['VideoModule__CloseModal']}
                    onClick={handleCloseModal}>
                    <span className={styles['VideoModule__CloseIconWrapper']}>
                        <Icon type="close" color="black800" size="medium" />
                    </span>
                    <span className="sr-only">{i18n.t('Modal.close')}</span>
                </button>

                <div className={styles['VideoModule__ModalPlayer']}>
                    <Vimeo
                        video={videoUrl}
                        responsive={true}
                        background={false}
                        showTitle={true}
                        showByline={true}
                        controls={true}
                    />
                </div>
            </div>
        </div>
    </div>
);

const VideoButton = ({ onClick, play, text = null }) => {
    return (
        <button
            className={styles['VideoModule__VideoButton']}
            aria-label={
                play ? i18n.t('VideoModule.pause') : i18n.t('VideoModule.play')
            }
            onClick={onClick}>
            <span className={styles['VideoModule__IconBackground']}>
                <span className={styles['VideoModule__IconWrapper']}>
                    <Icon
                        type={play ? 'pause' : 'play'}
                        color="black800"
                        size="xs"
                    />
                </span>
            </span>

            {text && (
                <span className={styles['VideoModule__ButtonText']}>
                    {text}
                </span>
            )}

            {!text && (
                <span className={styles['VideoModule__ButtonText']}>
                    {play
                        ? i18n.t('VideoModule.pause')
                        : i18n.t('VideoModule.play')}
                </span>
            )}
        </button>
    );
};

export default VideoModule;
